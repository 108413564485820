import { VideoClass } from '@/lib/types/api/video';
import { formatDate } from '@/lib/helpers/format-date';
import { isDateInPast } from '@/lib/helpers/is-date-in-past';
import styles from './AiringExpiringRating.module.scss';
interface AiringExpiringProps {
  video: VideoClass;
  className?: string;
}
const AiringExpiringRating = (props: AiringExpiringProps) => {
  const {
    video,
    className
  } = props;
  const {
    encore_date,
    expire_date,
    content_rating
  } = video;
  const stringArray = [];
  if (encore_date) {
    // a future encore date is not a common use case, but just in case
    const airingPrefix = isDateInPast(encore_date) ? 'Aired' : 'Airing';
    stringArray.push(`${airingPrefix} ${formatDate(encore_date)}`);
  }
  if (expire_date) {
    const expirePrefix = isDateInPast(expire_date) ? 'Expired' : 'Expires';
    stringArray.push(`${expirePrefix} ${formatDate(expire_date)}`);
  }
  if (content_rating && content_rating !== '') {
    stringArray.push(`Rating ${content_rating}`);
  }
  const joinedStrings = stringArray.join(' | ');
  return <p className={`${styles.aired_expired_rating}${className ? ` ${className}` : ''}`} data-sentry-component="AiringExpiringRating" data-sentry-source-file="AiringExpiringRating.tsx">
      {joinedStrings}
    </p>;
};
export default AiringExpiringRating;