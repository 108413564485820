import { VideoTypeEnum } from '@/lib/types/api/video';
import styles from './MezzanineVideoInitializer.module.scss';
import WatchVideoButton from '@/components/WatchVideoButton/WatchVideoButton';
import ITSImage from '@/components/ITSImage/ITSImage';
interface MezzanineVideoInitializerProps {
  videoType?: VideoTypeEnum;
  imgSrc: string;
  alt: string;
  onClick: () => void;
  showWatchButton?: boolean;
  verticalOffsetButton: boolean;
  width: number;
  className?: string;
}
const MezzanineVideoInitializer = (props: MezzanineVideoInitializerProps) => {
  const {
    videoType,
    showWatchButton = true,
    alt,
    onClick,
    imgSrc,
    verticalOffsetButton,
    width,
    className
  } = props;
  const aspectRatio = 9 / 16; // 0.5625

  let listOfSizes = [320, 480, 768, 1024, 1280, 1440];

  // loop through list of sizes and remove those above width prop
  listOfSizes = listOfSizes.filter(size => size <= width);

  // add width prop to list of sizes
  listOfSizes.push(width);

  // create array of tuples where each item is a pair of the size and the size times the aspect ratio
  const srcSetSizes: Array<[number, number]> = listOfSizes.map(size => [size, (size * aspectRatio)!]);
  let classNames = styles.mezzanine_video_initializer;
  if (className) {
    classNames += ` ${className}`;
  }
  return <div className={classNames} data-sentry-component="MezzanineVideoInitializer" data-sentry-source-file="MezzanineVideoInitializer.tsx">
      <ITSImage src={imgSrc} alt={alt} width={width} height={width * aspectRatio} srcSetSizes={srcSetSizes} loading="eager" data-sentry-element="ITSImage" data-sentry-source-file="MezzanineVideoInitializer.tsx" />
      {showWatchButton && <WatchVideoButton videoType={videoType || VideoTypeEnum.Video} onClick={() => onClick()} verticalOffset={verticalOffsetButton} />}
    </div>;
};
export default MezzanineVideoInitializer;