import { atomWithStorage } from 'jotai/utils';

let storedContinuousPlay

if (typeof window !== "undefined") {
  storedContinuousPlay = JSON.parse(window.localStorage.getItem('continuous-play')!);

  // Defaulting the user's autoplay setting to true by setting 'continuous-play' = true in
  // local storage if a setting is not yet defined
  if (storedContinuousPlay === undefined) {
    window.localStorage.setItem('continuous-play', JSON.stringify(true));
    storedContinuousPlay = true
  }
} else {
  storedContinuousPlay = true
}

export const continuousPlay = atomWithStorage<boolean>('continuous-play', storedContinuousPlay);
